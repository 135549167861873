import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import { HomePage } from "../pages/HomePage";
import { MenuPage } from "../pages/MenuPage";
import NotFoundPage from "../pages/NotFoundPage";
import AboutPage from '../pages/AboutPage';

const Index = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Exact match for root path */}
        <Route path="/" element={<HomePage />} />

        {/* Match /about and /about/ */}
        <Route path="/about" element={<AboutPage />} />
        <Route path="/about/" element={<AboutPage />} />

        {/* Match any path with a slug, with or without trailing slash */}
        <Route path="/:slug" element={<MenuPage />} />
        <Route path="/:slug/" element={<MenuPage />} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Index;