// src/analytics.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const initializeAnalytics = () => {
    console.log('Initializing Google Analytics');
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-8WTZX8L02C');
};

export const logPageView = (pathname) => {
    console.log(`Logging page view for: ${pathname}`);
    window.gtag('config', 'G-8WTZX8L02C', {
        page_path: pathname,
    });
};

export const usePageTracking = () => {
    const location = useLocation();
    
    useEffect(() => {
        logPageView(location.pathname);
    }, [location]);
};
